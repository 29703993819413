<template>
  <b-card>
    <button id="mediaLibrary">
      open ML
    </button>
  </b-card>
</template>

<script>

// not currently implemented, WIP
import { onBeforeMount } from '@vue/composition-api'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  setup() {
    const mlOptions = {
      cloud_name: 'mlfx',
      api_key: '12345',
      username: 'drewmacgibbon@gmail.com',
      timestamp: '1234567890',
      signature: 'abcdefgh',
      button_class: 'myBtn',
      button_caption: 'Insert Images',
      default_transformations: [
        [{ quality: 'auto' }, { fetch_format: 'auto' }],
        [{
          width: 80, height: 80, crop: 'fill', gravity: 'auto', radius: 'max',
        }, { fetch_format: 'auto', quality: 'auto' }],
      ],
    }

    function insertHandler(data) {
      console.log('returnedML', data)
    }
    onBeforeMount(() => {
      const script = document.createElement('script')
      script.setAttribute('src', 'https://media-library.cloudinary.com/global/all.js')
      document.head.appendChild(script)

      window.ml.cloudinary.openMediaLibrary(mlOptions, insertHandler, '#mediaLibrary')
    })
  },
}
</script>

<style lang="scss">

.error-img {
  border-radius: 1rem;
  max-width: 520px;
}
.text-max-width {
  max-width: 520px;
}

#postTitle {
  height: 42px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  &::placeholder {
    color: #adadad;
    padding-left: 6px;
    font-size: 1em;
  }
}
</style>
